import React from "react"
import { Link } from "gatsby"
import { Icon } from '../Subcomponent/SVG'

import Fade from 'react-reveal/Fade'
import { Parallax } from 'react-scroll-parallax';

const About = ({ data }) => {
  return (
    <section id="about">
      <div className="container">
        
        <div className="main-wrapper">

          <div className="main-wrapper--left">
            <Fade bottom duration={800} distance="50px">
              <h1>{data.about_section_title}</h1>
            </Fade>

            <Fade bottom duration={800} delay={200} distance="30px">
            <div className="lead-box">
              <div className="content">
                <p>{data.about_lead}</p>
                <Link className="button button--primary" to={data.about_cta_link}>
                  {data.about_cta}
                </Link>
              </div>
            </div>
            </Fade>
          </div>

          <div className="main-wrapper--right">
            <Parallax y={[-15, 5]}>
              <div
                className="cover-image"
                style={{ backgroundImage: 'url(' + data.about_cover_image.url + '' }}
              ></div>
            </Parallax>
          </div>

        </div>
        
        <div className="usp-wrapper">
          {data.about_usp_boxes.map((usp, i) => (
            <Fade bottom duration={600} delay={500 + (i * 150)} distance="30px" key={i}>
            <div className="usp-item">
              <div className="icon">
                <Icon type={usp.icon} />
              </div>
              <small>{usp.label}</small>
            </div>
            </Fade>
          ))}
        </div>

      </div>
    </section>
  )
};

export default About