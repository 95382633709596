import React from "react"
import ProductCard from "../Subcomponent/ProductCard"

const Products = ({ data, items }) => {
  const itemsSorted = items.sort(function(a, b){
    return a.node.data.sort - b.node.data.sort;
  });

  return (
    <section id="products">
      <div className="container">
        <div className="wrapper">
        
          <div className="title-wrapper">
            <h1>{data.products_section_title}</h1>
            <p>{data.products_lead}</p>
          </div>

          {itemsSorted.map((item, i) => (
            <ProductCard item={item} maxColor="9" />
          ))}

        </div>
      </div>
    </section>
  )
};

export default Products