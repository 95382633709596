import React from "react"
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import VideoHero from "../components/Index/VideoHero"
import About from "../components/Index/About"
import Products from "../components/Index/Products"
import Contact from "../components/Layout/Contact"
import Footer from "../components/Layout/Footer"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Kezdőlap"/>
      <VideoHero />
      <About data={data.prismicHome.data} />
      <Products data={data.prismicHome.data} items={data.allPrismicProduct.edges} />
      <Contact />
      <Footer />
    </Layout>
  )
}

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.any,
}

export const pageQuery = graphql`
  query {
    prismicHome {
      data {
        about_section_title
        about_lead
        about_cta
        about_cta_link
        about_cover_image {
          url
        }
        about_usp_boxes {
          icon
          label
        }
        products_section_title
        products_lead
      }
    }
    allPrismicProduct {
      edges {
        node {
          data {
            name {
              text
            }
            sort
            phisical_scale
            featured_variant
            variant {
              variant_color
              variant_background_color
              variant_name {
                text
              }
              variant_image {
                thumbnails {
                  thumbnail {
                    url
                  }
                }
              }
            }
          }
          uid
          id
        }
      }
    }
  }
`
