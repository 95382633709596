import React from "react"
import VideoBg from 'reactjs-videobg'
import { Parallax } from 'react-scroll-parallax'
import { Logo } from '../Subcomponent/SVG'

const VideoHero = () => (
  <Parallax y={[30, -30]}>
  <div id="videohero">
    <div className="inside">
      <div className="logo">
        <Logo />
      </div>
      <VideoBg
        wrapperClass="video"
        poster="https://cdn.viragmajor.hu/home.jpg"
      >
        <VideoBg.Source src="https://cdn.viragmajor.hu/home.mp4" type="video/mp4" />
        <VideoBg.Source src="https://cdn.viragmajor.hu/home.webm" type="video/webm" />
        <VideoBg.Source src="https://cdn.viragmajor.hu/home.ogg" type="video/ogg" />
      </VideoBg>
    </div>
  </div>
  </Parallax>
)

export default VideoHero